<template>
	<div>

		<!-- Sign Up Image And Headings -->
		<div class="sign-up-header" style="background-image: url('images/bg-signup.jpg')">
		</div>
		<!-- / Sign Up Image And Headings -->
		
		<!-- Sign Up Form -->
		<a-card :bordered="false" class="card-signup header-solid h-full" style="margin-top: -250px;" :bodyStyle="{paddingTop: 0}">
			<template #title>
				<h5 class="font-semibold text-center">忘记密码</h5>
			</template>
			<a-form
				id="components-form-demo-normal-login"
				:form="form"
				class="login-form"
				@submit="handleSubmit"
			>
				<a-form-item class="mb-10">
					<a-input
						v-decorator="[
						'username',
						{ rules: [{ required: true, message: '企业邮箱不正确' }] },
						]"
						placeholder="请输入企业邮箱"
					>
					</a-input>
				</a-form-item>
				<a-form-item class="mb-10">
                    <a-row :gutter="8">
                        <a-col :span="19">
                            <a-input
                                v-decorator="[
                                'code',
                                { rules: [{ required: true, message: '邮箱验证码不正确' }] },
                                ]"
                                placeholder="请输入邮箱验证码"
                            >
                            </a-input>
                        </a-col>
                        <a-col :span="5">
                            <a-button type="primary" block v-show="show" :disabled="disabled" @click="getCode">
                                获取验证码
                            </a-button>
                            <a-button  v-show="!show" block>
                                {{count}}s重新获取
                            </a-button>
                        </a-col>
                    </a-row>
				</a-form-item>
				<a-form-item class="mb-5">
					<a-input
						v-decorator="[
						'password',
						{ rules: [{ required: true, message: '请输入您的新密码' }] },
						]"
						type="password"
						placeholder="请输入您的新密码"
					>
					</a-input>
				</a-form-item>
				<a-form-item>
					<a-button type="primary"  block html-type="submit" class="login-form-button">
						确认更改
					</a-button> 
				</a-form-item>
			</a-form>
            <p class="font-semibold text-muted text-center">更改完成? 
                <router-link to="sign-in">
                    点击前往登录
                </router-link>
            </p>
        </a-card>
		<!-- / Sign Up Form -->

	</div>
</template>

<script>
    import axios from 'axios'
    import urlencode from "urlencode"
	export default ({
		data() {
			return {
                email:'',
                show:true,
                count: '',
		        timer: null,
                disabled:false,
                password:'',
                code:''
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		methods: {
			// Handles input validation after submission.
			handleSubmit(e) {
				e.preventDefault();
                var _that = this
				this.form.validateFields((err, values) => {
					if ( !err ) {
                        values.password_confirmation = values.password;
						axios.post(
							'http://user.winndoo.cn/api/forgotpass', 
							values,
                            {
                                headers: {
                                        "Content-Type": "multipart/form-data"
                                }
                            }
						).then(response => {
                            if(response.data.status_code == 201){
                                this.$notification.open({
                                    message: '通知',
                                    description:response.data.data.msg
                                });
                            }
						}).catch(error => {
						})
					}
				});
			},
            getCode(){
                this.disabled = true
                var _that = this
                const TIME_COUNT = 60;
				this.form.validateFields((err, values) => {
                    console.log(values)
					_that.email = values.username
				});
                if(this.email == ''){
                    this.$notification.open({
                        message: '通知',
                        description:'请输入企业邮箱'
                    });
                    return
                }
                let email = urlencode(this.email)
                axios.get(
                    'http://user.winndoo.cn/api/getEmailCode?email='+email, 
                ).then(response => {
                    this.disabled = false
                    if(response.data.code == 201){
                        this.$notification.open({
                            message: '通知',
                            description:response.data.msg
                        });
                    }
                    if(response.data.code == 200){
                        this.$notification.open({
                            message: '通知',
                            description:'获取成功'
                        });
                        if (!this.timer) {
                            this.count = TIME_COUNT;
                            this.show = false;
                            this.timer = setInterval(() => {
                                if (this.count > 0 && this.count <= TIME_COUNT) {
                                    this.count--;
                                } else {
                                    this.show = true;
                                    clearInterval(this.timer);
                                    this.timer = null;
                                }
                            }, 1000)
                        }
                        console.log(response.headers);
                    }
                }).catch(error => {
                    this.disabled = false
                    this.$notification.open({
                        message: '通知',
                        description:'获取失败'
                    });
                })
            }
		},
	})

</script>

<style lang="scss">
</style>